import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Living: IScene = {
	name: 'Living',
	pan: '0deg',
	hotspot: {
		x: 70,
		y: 310,
	},
	images: {
		scheme1: Scheme1.Living,
		scheme2: Scheme2.Living,
	},
	default: {
		latitude: -0.0301800330127584,
		longitude: 6.150693240032656,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5107902420042922,
				longitude: 1.3423933944998145,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.10061994233250449,
					longitude: 1.5698958811452446,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.198497199784599,
				longitude: 0.6655481893203234,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.02459117093887908,
					longitude: 0.8069194384618047,
				},
			},
		},
	],
};

export default Living;
