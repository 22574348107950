import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='132.26' y='230.79' width='22.5' height='35.62' />
			<rect x='126.11' y='234.35' width='6.15' height='12' />
			<line x1='127.08' y1='246.35' x2='127.08' y2='234.35' />
			<rect x='126.11' y='250.85' width='6.15' height='12' />
			<line x1='127.08' y1='250.85' x2='127.08' y2='262.85' />
			<rect x='154.76' y='234.35' width='6.15' height='12' />
			<line x1='159.93' y1='246.35' x2='159.93' y2='234.35' />
			<rect x='154.76' y='250.85' width='6.15' height='12' />
			<line x1='159.93' y1='250.85' x2='159.93' y2='262.85' />
			<rect x='42.99' y='275' width='22.5' height='22.5' />
			<rect x='23.51' y='228.4' width='65.27' height='24' />
			<polyline points='23.51,250.15 26.51,250.15 26.51,231.4 85.77,231.4 85.77,250.15 88.78,250.15 ' />
			<line x1='65.8' y1='252.4' x2='65.74' y2='231.4' />
			<line x1='46.48' y1='252.4' x2='46.48' y2='231.4' />
			<rect x='27.18' y='232.01' width='57.93' height='4.5' />
			<rect x='32.11' y='321.8' width='45' height='9' />
			<rect x='36.61' y='325.59' width='36' height='1.12' />
			<polyline points='57.61,326.71 57.61,328.4 51.61,328.4 51.61,326.71 ' />
			<polyline points='51.61,325.59 51.61,323.9 57.61,323.9 57.61,325.59 ' />
			<rect x='75.01' y='3.58' width='45.75' height='0.75' />
			<polyline points='75.39,18.18 75.39,63.58 120.39,63.58 120.39,35.52 ' />
			<polyline points='120.39,21.5 120.39,4.33 75.39,4.33 75.39,12.16 ' />
			<rect x='60.81' y='3.58' width='13.29' height='13.29' />
			<rect x='121.68' y='3.58' width='13.29' height='13.29' />
			<polyline points='78.76,12.86 78.76,5.76 96.39,5.76 96.39,16.52 ' />
			<polyline points='101.63,17.61 117.01,17.61 117.01,5.76 99.76,5.76 99.76,17.22 ' />
			<polygon points='75.01,12.08 75.01,18.04 120.76,35.67 120.76,21.58 ' />
			<rect x='34.02' y='222.6' width='45.75' height='0.75' />
			<polyline points='34.39,208.75 34.39,163.35 79.39,163.35 79.39,191.41 ' />
			<polyline points='79.39,205.43 79.39,222.6 34.39,222.6 34.39,214.77 ' />
			<rect x='80.69' y='210.06' width='13.29' height='13.29' />
			<rect x='19.81' y='210.06' width='13.29' height='13.29' />
			<polyline points='37.77,214.07 37.77,221.17 55.39,221.17 55.39,210.41 ' />
			<polyline points='60.63,209.32 76.02,209.32 76.02,221.17 58.77,221.17 58.77,209.71 ' />
			<polygon points='34.02,214.85 34.02,208.89 79.77,191.26 79.77,205.35 ' />
			<rect x='230.5' y='188.63' width='36.5' height='18' />
			<rect x='242' y='180.2' width='1.5' height='8.44' />
			<rect x='243.5' y='179.45' width='10.5' height='1.5' />
			<rect x='254' y='180.2' width='1.5' height='8.44' />
			<rect x='242' y='192.72' width='13.5' height='4.5' />
			<rect x='242.75' y='193.47' width='1.73' height='3' />
			<rect x='245.11' y='193.47' width='9.64' height='3' />
			<polyline points='246.5,202.85 246.5,204.54 250.62,204.54 250.62,202.85 ' />
			<polyline points='250.62,201.72 250.62,200.04 246.5,200.04 246.5,201.72 ' />
			<rect x='242' y='201.72' width='13.5' height='1.12' />
		</g>
	);
};

export default Furniture;
