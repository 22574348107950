import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='125.12' y1='303.79' x2='126.62' y2='303.79' />
			<line x1='128.63' y1='303.79' x2='183' y2='303.79' />
			<polyline points='184.01,303.79 185.51,303.79 185.51,302.29 ' />
			<line x1='185.51' y1='300.14' x2='185.51' y2='290.49' />
			<line x1='185.51' y1='289.41' x2='185.51' y2='287.91' />
			<line x1='125.69' y1='294.79' x2='125.69' y2='296.29' />
			<line x1='125.69' y1='298.66' x2='125.69' y2='309.35' />
			<polyline points='125.69,310.54 125.69,312.04 127.19,312.04 ' />
			<line x1='128.95' y1='312.04' x2='141.3' y2='312.04' />
			<polyline points='142.19,312.04 143.69,312.04 143.69,310.54 ' />
			<line x1='143.69' y1='308.16' x2='143.69' y2='297.47' />
			<line x1='143.69' y1='296.29' x2='143.69' y2='294.79' />
			<line x1='194.51' y1='287.91' x2='193.01' y2='287.91' />
			<line x1='191.26' y1='287.91' x2='183.38' y2='287.91' />
			<polyline points='182.51,287.91 181.01,287.91 181.01,286.41 ' />
			<line x1='181.01' y1='284.57' x2='181.01' y2='267.08' />
			<polyline points='181.01,266.16 181.01,264.66 182.51,264.66 ' />
			<line x1='184.26' y1='264.66' x2='192.13' y2='264.66' />
			<line x1='193.01' y1='264.66' x2='194.51' y2='264.66' />
			<line x1='185.51' y1='264.66' x2='185.51' y2='263.16' />
			<line x1='185.51' y1='261.3' x2='185.51' y2='238.95' />
			<line x1='185.51' y1='238.02' x2='185.51' y2='236.52' />
			<line x1='101.93' y1='129.55' x2='101.93' y2='128.05' />
			<line x1='101.93' y1='126.18' x2='101.93' y2='94.3' />
			<line x1='101.93' y1='93.36' x2='101.93' y2='91.86' />
			<line x1='60.39' y1='131.05' x2='61.89' y2='131.05' />
			<line x1='63.85' y1='131.05' x2='106.95' y2='131.05' />
			<line x1='107.93' y1='131.05' x2='109.43' y2='131.05' />
			<line x1='99.85' y1='187.5' x2='101.35' y2='187.5' />
			<line x1='103.27' y1='187.5' x2='136.01' y2='187.5' />
			<line x1='136.97' y1='187.5' x2='138.47' y2='187.5' />
			<line x1='202.48' y1='199.17' x2='203.98' y2='199.17' />
			<line x1='205.78' y1='199.17' x2='222.85' y2='199.17' />
			<line x1='223.74' y1='199.17' x2='225.24' y2='199.17' />
		</g>
	);
};

export default Dashed;
