import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	pan: '0deg',
	hotspot: {
		x: 155,
		y: 280,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: -0.033681466531968685,
		longitude: 0.01015802564753985,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.24072541322335494,
				longitude: 6.189396295267891,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.027817074072192582,
					longitude: 6.268695426062184,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.344278060993195,
				longitude: 0.1997393886882563,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.058435866022969574,
					longitude: 4.701653657771356,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.18585578826262084,
				longitude: 6.2678338409906384,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: -0.03322673527155162,
					longitude: 6.264548260764036,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3497911414683208,
				longitude: 6.2701106869198915,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.0707411523720256,
					longitude: 6.268744633805005,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.30948572031778854,
				longitude: 6.149113012630994,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.051600628920787095,
					longitude: 3.132938695177368,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.46287123072173353,
				longitude: 4.519428407970379,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.022181635874908068,
					longitude: 4.777731980296634,
				},
			},
		},
	],
};

export default Kitchen;
