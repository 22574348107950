import {useSelector} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SCSS from './RoomSelection.module.scss';
import IScene from '../../../../scenes/IScene';
import {useAppDispatch} from '../../../../store';
import {
	selectSCENES,
	selectScheme,
	fetchPanorama,
	closePOP,
	updateClicked,
} from '../../../../features/panorama/panoramaSlice';

// const _ = require("lodash");

const ColorButton = withStyles((theme) => ({
	root: {
		color: 'rgba(255, 255, 255, 0.75) !important',
		borderColor: 'rgba(255, 255, 255, 0.3)',
		backgroundColor: 'transparent',
		fontSize: '14px',
		'&:hover': {
			color: 'rgba(255, 255, 255, 1) !important',
			borderColor: 'rgba(255, 255, 255, 0.3)',
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
	},
}))(Button);

const RoomSelection = () => {
	const dispatch = useAppDispatch();
	const SCENES = useSelector(selectSCENES);
	const scheme = useSelector(selectScheme);
	// console.log('SCENES', SCENES);
	const change = (scene: string) => {
		dispatch(updateClicked('menu'));
		dispatch(fetchPanorama(scene));
		dispatch(closePOP());
	};
	const Buttons = SCENES.map((btn: IScene, index: number) => (
		<ColorButton onClick={() => change(btn.name)} key={index}>
			{btn.name}
		</ColorButton>
	));
	return (
		<div className={SCSS.RoomSelection}>
			<div className={SCSS.Padding}>
				<div className={SCSS.LogoTheGrand} />
				<h1>Unit 07</h1>
				<h3>- {scheme === 'scheme1' ? 'Scheme 1' : 'Scheme 2'} -</h3>
				<ButtonGroup orientation='vertical' fullWidth size='small'>
					{Buttons}
				</ButtonGroup>
				<div className={SCSS.LogoAllure} />
				{/* <div className={SCSS.Developed}>
					<small>Virtual Tour Developed By: </small>
					<br />
					<a
						href='https://www.wcimediastudios.com'
						target='_blank'
						rel='noreferrer'>
						WCI | Rally collaborative
					</a>
				</div> */}
			</div>
		</div>
	);
};

export default RoomSelection;
