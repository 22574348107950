import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Hallway: IScene = {
	name: 'Hallway',
	pan: '0deg',
	hotspot: {
		x: 165,
		y: 175,
	},
	images: {
		scheme1: Scheme1.Hallway,
		scheme2: Scheme2.Hallway,
	},
	default: {
		latitude: -0.10476638585263576,
		longitude: 3.1247612893030854,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.20218986872613653,
				longitude: 3.3458049312367684,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.3859136928913265,
					longitude: 3.140471500317499,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3027332397161786,
				longitude: 6.255871137876498,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: -0.06954618989434946,
					longitude: 6.268810323343423,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.44679179128305124,
				longitude: 6.0974331963680175,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.027817074072192582,
					longitude: 6.268695426062184,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.23294806011259928,
				longitude: 3.6975653925637455,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.043182083673268146,
					longitude: 3.621182198935368,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.871226587579927,
				longitude: 5.442320123067259,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.051600628920787095,
					longitude: 3.132938695177368,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3674175990215267,
				longitude: 1.3402924219828685,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.035472536931591137,
					longitude: 1.6224929072995893,
				},
			},
		},
	],
};

export default Hallway;
