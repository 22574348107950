import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	pan: '0deg',
	hotspot: {
		x: 50,
		y: 165,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.051600628920787095,
		longitude: 3.132938695177368,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5755323287265561,
				longitude: 1.5175381973832973,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.09954017916129754,
					longitude: 1.5740452593865097,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2255298401435748,
				longitude: 1.56391114046194,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.05023273598026856,
					longitude: 1.4597223393916057,
				},
			},
		},
	],
};

export default Bedroom;
