import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MainBath: IScene = {
	name: 'Main Bath',
	pan: '0deg',
	hotspot: {
		x: 265,
		y: 125,
	},
	images: {
		scheme1: Scheme1.MainBath,
		scheme2: Scheme2.MainBath,
	},
	default: {
		latitude: -0.058435866022969574,
		longitude: 4.701653657771356,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.8173250453539955,
				longitude: 3.4667651982401253,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.10476638585263576,
					longitude: 3.1247612893030854,
				},
			},
		},
	],
};

export default MainBath;
