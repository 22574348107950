import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<polyline
				className={SCSS.Interiors}
				id='Interior'
				points='48.64,0.25 193.38,0.25 193.38,78.77 175.8,78.77 175.8,156.89 235.18,156.89 
235.18,74.12 289.08,74.12 289.08,157.6 319.76,157.6 319.76,193.47 269.37,193.47 269.37,208.75 195.51,208.75 195.51,313.71 
127.08,313.71 127.08,337.59 5.62,337.59 17.22,116.13 48.84,116.13 '
			/>
			<polygon
				className={SCSS.Balcony}
				id='Balcony'
				points='109.41,437.24 109.41,337.57 5.62,337.57 0.26,437.51 '
			/>
		</g>
	);
};

export default Backdrop;
