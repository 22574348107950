import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<circle cx='272.26' cy='88.42' r='0.75' />
			<rect x='315.63' y='162.11' width='4.13' height='27.02' />
			<line x1='253.55' y1='143.99' x2='242.75' y2='143.99' />
			<path
				d='M244.48,137.02c0.31,0.34,0.84,0.36,1.18,0.04c0.34-0.31,0.36-0.84,0.04-1.18c-0.31-0.34-0.84-0.36-1.18-0.04
c-0.01,0.01-0.03,0.03-0.04,0.04'
			/>
			<path d='M241.52,137.2c0.1,0,0.18-0.08,0.19-0.18' />
			<line x1='240.4' y1='137.2' x2='241.52' y2='137.2' />
			<path d='M240.21,137.01c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='240.21' y1='136.59' x2='240.21' y2='137.01' />
			<line x1='240.21' y1='135.89' x2='240.21' y2='136.31' />
			<path d='M240.4,135.7c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='241.52' y1='135.7' x2='240.4' y2='135.7' />
			<path d='M241.71,135.88c0-0.1-0.09-0.18-0.19-0.18' />
			<line x1='239.84' y1='136.31' x2='239.84' y2='136.59' />
			<line x1='240.39' y1='136.31' x2='239.84' y2='136.31' />
			<line x1='239.84' y1='136.59' x2='240.39' y2='136.59' />
			<line x1='242.75' y1='128.92' x2='242.75' y2='135.88' />
			<line x1='253.55' y1='128.92' x2='242.75' y2='128.92' />
			<line x1='253.55' y1='143.99' x2='253.55' y2='128.92' />
			<line x1='242.75' y1='137.02' x2='242.75' y2='143.99' />
			<path
				d='M246.82,118.23c-0.41-0.03-0.57-0.07-0.71-0.11c-0.13-0.04-0.24-0.08-0.35-0.14
c-0.11-0.06-0.23-0.12-0.32-0.19c-0.1-0.07-0.18-0.13-0.25-0.2c-0.08-0.08-0.15-0.16-0.22-0.26c-0.08-0.12-0.17-0.26-0.24-0.4
c-0.08-0.16-0.14-0.32-0.19-0.47c-0.05-0.16-0.09-0.31-0.12-0.47c-0.04-0.18-0.07-0.38-0.09-0.57c-0.03-0.24-0.05-0.47-0.06-0.71
c-0.02-0.31-0.03-0.62-0.03-0.97c-0.01-0.43-0.01-0.91,0.01-1.41c0.01-0.47,0.04-0.95,0.08-1.34c0.03-0.32,0.07-0.56,0.14-0.85
c0.05-0.19,0.11-0.4,0.18-0.57c0.05-0.15,0.11-0.27,0.17-0.39c0.06-0.11,0.13-0.21,0.23-0.33c0.1-0.12,0.23-0.25,0.36-0.35
c0.12-0.1,0.24-0.18,0.35-0.24c0.09-0.06,0.18-0.11,0.28-0.15c0.09-0.04,0.19-0.07,0.3-0.09c0.1-0.02,0.19-0.04,0.31-0.06
c0.12-0.02,0.27-0.04,0.65-0.04'
			/>
			<path
				d='M252.92,118.23c0.3,0,0.53-0.01,0.74-0.04c0.1-0.01,0.2-0.02,0.3-0.04s0.19-0.03,0.28-0.05
c0.1-0.02,0.19-0.03,0.29-0.05c0.1-0.02,0.2-0.04,0.29-0.05c0.1-0.02,0.2-0.04,0.29-0.06c0.1-0.02,0.19-0.04,0.29-0.07
c0.09-0.02,0.19-0.05,0.28-0.08c0.09-0.03,0.18-0.06,0.27-0.09c0.09-0.03,0.17-0.07,0.25-0.11s0.16-0.08,0.24-0.12
c0.16-0.09,0.31-0.18,0.45-0.28s0.28-0.21,0.4-0.31s0.24-0.21,0.34-0.31c0.1-0.1,0.19-0.2,0.26-0.29c0.04-0.05,0.07-0.09,0.1-0.13
c0.03-0.04,0.06-0.09,0.08-0.13s0.05-0.09,0.08-0.15c0.03-0.05,0.05-0.11,0.08-0.18c0.03-0.07,0.06-0.14,0.08-0.21
c0.03-0.07,0.05-0.15,0.08-0.23s0.05-0.16,0.07-0.24c0.02-0.08,0.04-0.16,0.06-0.24c0.04-0.16,0.06-0.32,0.08-0.47
c0.01-0.08,0.02-0.15,0.02-0.22c0.01-0.07,0.01-0.14,0.01-0.22c0.01-0.14,0.01-0.29,0.01-0.46c0-0.17,0-0.35,0-0.54
c0-0.19-0.01-0.38-0.02-0.55c-0.01-0.09-0.01-0.17-0.02-0.25c-0.01-0.08-0.02-0.16-0.03-0.24c-0.01-0.08-0.02-0.15-0.04-0.23
c-0.01-0.04-0.01-0.07-0.02-0.11c-0.01-0.04-0.01-0.07-0.02-0.11c-0.01-0.07-0.03-0.14-0.04-0.2s-0.03-0.12-0.04-0.18
c-0.01-0.03-0.02-0.05-0.02-0.08c-0.01-0.03-0.02-0.05-0.03-0.08c-0.02-0.05-0.04-0.11-0.07-0.16c-0.03-0.05-0.06-0.11-0.09-0.17
c-0.03-0.06-0.07-0.12-0.11-0.17c-0.04-0.06-0.07-0.12-0.11-0.18c-0.04-0.06-0.08-0.12-0.12-0.17s-0.09-0.11-0.14-0.17
c-0.05-0.06-0.1-0.12-0.16-0.18c-0.06-0.06-0.12-0.12-0.19-0.18c-0.07-0.06-0.14-0.12-0.22-0.19c-0.15-0.12-0.32-0.25-0.49-0.36
c-0.08-0.06-0.17-0.11-0.25-0.16c-0.08-0.05-0.16-0.09-0.23-0.13c-0.08-0.04-0.15-0.07-0.22-0.1c-0.07-0.03-0.14-0.06-0.21-0.08
s-0.14-0.05-0.2-0.07s-0.14-0.04-0.2-0.06s-0.14-0.04-0.21-0.06s-0.14-0.04-0.21-0.05c-0.07-0.02-0.15-0.04-0.23-0.05
c-0.08-0.02-0.17-0.04-0.27-0.05c-0.05-0.01-0.1-0.02-0.16-0.03c-0.05-0.01-0.11-0.02-0.17-0.03c-0.12-0.02-0.25-0.04-0.39-0.05
c-0.14-0.02-0.28-0.03-0.42-0.04c-0.14-0.01-0.28-0.02-0.41-0.02'
			/>
			<line x1='252.92' y1='118.23' x2='246.82' y2='118.23' />
			<line x1='252.92' y1='107.91' x2='247.29' y2='107.91' />
			<line x1='174.61' y1='71.1' x2='185.41' y2='71.1' />
			<path
				d='M183.68,63c-0.31-0.34-0.84-0.36-1.18-0.04c-0.34,0.31-0.36,0.84-0.04,1.18c0.31,0.34,0.84,0.36,1.18,0.04
c0.01-0.01,0.03-0.03,0.04-0.04'
			/>
			<path d='M186.45,64.14c0,0.1,0.09,0.18,0.19,0.18' />
			<line x1='187.76' y1='64.32' x2='186.63' y2='64.32' />
			<path d='M187.76,64.32c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='187.95' y1='63.71' x2='187.95' y2='64.13' />
			<line x1='187.95' y1='63' x2='187.95' y2='63.42' />
			<path d='M187.95,63c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='186.63' y1='62.82' x2='187.76' y2='62.82' />
			<path d='M186.63,62.82c-0.1,0-0.18,0.08-0.19,0.18' />
			<line x1='188.32' y1='63.42' x2='188.32' y2='63.71' />
			<line x1='187.77' y1='63.42' x2='188.32' y2='63.42' />
			<line x1='188.32' y1='63.71' x2='187.77' y2='63.71' />
			<line x1='185.41' y1='56.03' x2='185.41' y2='63' />
			<line x1='174.61' y1='56.03' x2='185.41' y2='56.03' />
			<line x1='174.61' y1='71.1' x2='174.61' y2='56.03' />
			<line x1='185.41' y1='64.14' x2='185.41' y2='71.1' />
			<path
				d='M181.33,45.34c0.41-0.03,0.57-0.07,0.71-0.11c0.13-0.04,0.24-0.08,0.35-0.14c0.11-0.06,0.23-0.12,0.32-0.19
c0.1-0.07,0.18-0.13,0.25-0.2c0.08-0.08,0.15-0.16,0.22-0.26c0.08-0.12,0.17-0.26,0.24-0.4c0.08-0.16,0.14-0.32,0.19-0.47
c0.05-0.16,0.09-0.31,0.12-0.47c0.04-0.18,0.07-0.38,0.09-0.57c0.03-0.24,0.05-0.47,0.06-0.71c0.02-0.31,0.03-0.62,0.03-0.97
c0.01-0.43,0.01-0.91-0.01-1.41c-0.01-0.47-0.04-0.95-0.08-1.34c-0.03-0.32-0.07-0.56-0.14-0.85c-0.05-0.19-0.11-0.4-0.18-0.57
c-0.05-0.15-0.11-0.27-0.17-0.39c-0.06-0.11-0.13-0.21-0.23-0.33c-0.1-0.12-0.23-0.25-0.36-0.35c-0.12-0.1-0.24-0.18-0.35-0.24
c-0.09-0.06-0.18-0.11-0.28-0.15c-0.09-0.04-0.19-0.07-0.3-0.09c-0.1-0.02-0.19-0.04-0.31-0.06c-0.12-0.02-0.27-0.04-0.65-0.04'
			/>
			<path
				d='M175.24,45.34c-0.3,0-0.53-0.01-0.74-0.04c-0.1-0.01-0.2-0.02-0.3-0.04s-0.19-0.03-0.28-0.05
c-0.1-0.02-0.19-0.03-0.29-0.05c-0.1-0.02-0.2-0.04-0.29-0.05s-0.2-0.04-0.29-0.06c-0.1-0.02-0.19-0.04-0.29-0.07
c-0.09-0.02-0.19-0.05-0.28-0.08s-0.18-0.06-0.27-0.09c-0.09-0.03-0.17-0.07-0.25-0.11c-0.08-0.04-0.16-0.08-0.24-0.12
c-0.16-0.09-0.31-0.18-0.45-0.28c-0.14-0.1-0.28-0.21-0.4-0.31c-0.13-0.11-0.24-0.21-0.34-0.31s-0.19-0.2-0.26-0.29
c-0.04-0.05-0.07-0.09-0.1-0.13c-0.03-0.04-0.06-0.09-0.08-0.13c-0.03-0.05-0.05-0.09-0.08-0.15c-0.03-0.05-0.05-0.11-0.08-0.18
c-0.03-0.07-0.06-0.14-0.08-0.21s-0.05-0.15-0.08-0.23c-0.03-0.08-0.05-0.16-0.07-0.24c-0.02-0.08-0.04-0.16-0.06-0.24
c-0.04-0.16-0.06-0.32-0.08-0.47c-0.01-0.08-0.02-0.15-0.02-0.22s-0.01-0.14-0.01-0.22c-0.01-0.14-0.01-0.29-0.01-0.46
c0-0.17,0-0.35,0-0.54c0-0.19,0.01-0.38,0.02-0.55c0.01-0.09,0.01-0.17,0.02-0.25c0.01-0.08,0.02-0.16,0.03-0.24
c0.01-0.08,0.02-0.15,0.04-0.23c0.01-0.04,0.01-0.07,0.02-0.11c0.01-0.04,0.01-0.07,0.02-0.11c0.01-0.07,0.03-0.14,0.04-0.2
c0.01-0.06,0.03-0.12,0.04-0.18c0.01-0.03,0.02-0.05,0.02-0.08s0.02-0.05,0.03-0.08c0.02-0.05,0.04-0.1,0.07-0.16
c0.03-0.05,0.06-0.11,0.09-0.17s0.07-0.12,0.11-0.17c0.04-0.06,0.07-0.12,0.11-0.18c0.04-0.06,0.08-0.12,0.12-0.17
c0.04-0.06,0.09-0.11,0.14-0.17c0.05-0.06,0.1-0.12,0.16-0.18c0.06-0.06,0.12-0.12,0.19-0.18c0.07-0.06,0.14-0.12,0.22-0.19
c0.15-0.12,0.32-0.25,0.49-0.36c0.08-0.06,0.17-0.11,0.25-0.16c0.08-0.05,0.16-0.09,0.23-0.13c0.08-0.04,0.15-0.07,0.22-0.1
c0.07-0.03,0.14-0.06,0.21-0.08s0.14-0.05,0.2-0.07s0.14-0.04,0.2-0.06s0.14-0.04,0.21-0.06s0.14-0.04,0.21-0.05
c0.07-0.02,0.15-0.04,0.23-0.05c0.08-0.02,0.17-0.04,0.27-0.05c0.05-0.01,0.1-0.02,0.16-0.03s0.11-0.02,0.17-0.03
c0.12-0.02,0.25-0.04,0.39-0.05s0.28-0.03,0.42-0.04s0.28-0.02,0.41-0.02'
			/>
			<line x1='175.24' y1='45.34' x2='181.33' y2='45.34' />
			<line x1='175.24' y1='35.03' x2='180.87' y2='35.03' />
			<line x1='134.94' y1='135.45' x2='134.94' y2='115.01' />
			<line x1='178.41' y1='235.77' x2='178.41' y2='214.77' />
			<line x1='103.58' y1='334.21' x2='77.01' y2='334.21' />
			<line x1='103.58' y1='337.59' x2='77.01' y2='337.59' />
			<rect x='145.43' y='3.95' width='43.92' height='20.74' />
			<line x1='189.35' y1='24.69' x2='175.45' y2='14.98' />
			<line x1='175.46' y1='13.31' x2='189.35' y2='3.95' />
			<line x1='172.85' y1='13.65' x2='145.43' y2='3.95' />
			<line x1='145.43' y1='24.69' x2='172.85' y2='14.64' />
			<circle cx='174.24' cy='14.14' r='1.48' />
			<line x1='145.06' y1='27.58' x2='155.85' y2='27.58' />
			<line x1='158.13' y1='27.58' x2='183.95' y2='27.58' />
			<path d='M140.92,294.41c-4.07-1.2-8.39-1.2-12.46,0' />
			<circle cx='155.67' cy='310.7' r='0.19' />
			<line x1='156.25' y1='311.36' x2='156.25' y2='310.04' />
			<polyline points='157.87,308.83 166.45,308.83 166.45,297.58 148.45,297.58 148.45,308.83 157.03,308.83 ' />
			<path
				d='M157.74,305.02c0.38-0.16,0.56-0.6,0.41-0.98s-0.6-0.56-0.98-0.41s-0.56,0.6-0.41,0.98
c0.08,0.18,0.22,0.33,0.41,0.41'
			/>
			<path d='M176.13,270.06c-1.2,4.07-1.2,8.39,0,12.46' />
			<path
				d='M178.76,269c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='178.51' y1='268.6' x2='179.56' y2='268.6' />
			<line x1='178.51' y1='269' x2='179.56' y2='269' />
			<path
				d='M178.76,273.98c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='178.51' y1='273.58' x2='179.56' y2='273.58' />
			<line x1='178.51' y1='273.98' x2='179.56' y2='273.98' />
			<path
				d='M178.76,283.98c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='178.51' y1='283.98' x2='179.56' y2='283.98' />
			<line x1='178.51' y1='283.57' x2='179.56' y2='283.57' />
			<path
				d='M178.76,279c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='178.51' y1='279' x2='179.56' y2='279' />
			<line x1='178.51' y1='278.6' x2='179.56' y2='278.6' />
			<line x1='180.88' y1='287.54' x2='180.88' y2='265.04' />
			<line x1='181.4' y1='287.54' x2='181.4' y2='282.22' />
			<line x1='181.4' y1='281.98' x2='181.4' y2='265.04' />
			<line x1='193.09' y1='287.54' x2='193.09' y2='282.22' />
			<line x1='193.09' y1='281.98' x2='193.09' y2='270.59' />
			<line x1='193.09' y1='270.36' x2='193.09' y2='265.04' />
			<line x1='181.97' y1='287.54' x2='181.97' y2='282.22' />
			<line x1='181.97' y1='281.98' x2='181.97' y2='270.59' />
			<line x1='181.97' y1='270.36' x2='181.97' y2='265.04' />
			<line x1='182.26' y1='287.54' x2='182.26' y2='282.22' />
			<line x1='182.26' y1='281.98' x2='182.26' y2='270.59' />
			<line x1='182.26' y1='270.36' x2='182.26' y2='265.04' />
			<line x1='191.95' y1='280.72' x2='191.95' y2='271.85' />
			<line x1='191.95' y1='269.1' x2='191.95' y2='265.04' />
			<line x1='191.95' y1='283.48' x2='191.95' y2='287.54' />
			<line x1='192.24' y1='280.73' x2='192.24' y2='271.85' />
			<line x1='192.24' y1='269.1' x2='192.24' y2='265.04' />
			<line x1='192.24' y1='283.48' x2='192.24' y2='287.54' />
			<line x1='190.52' y1='280.62' x2='190.52' y2='271.96' />
			<line x1='190.52' y1='269' x2='190.52' y2='265.04' />
			<line x1='190.52' y1='283.59' x2='190.52' y2='287.54' />
			<line x1='190.81' y1='280.62' x2='190.81' y2='271.96' />
			<line x1='190.81' y1='269' x2='190.81' y2='265.04' />
			<line x1='190.81' y1='283.59' x2='190.81' y2='287.54' />
			<line x1='189.38' y1='280.71' x2='189.38' y2='271.87' />
			<line x1='189.38' y1='269.09' x2='189.38' y2='265.04' />
			<line x1='189.38' y1='283.49' x2='189.38' y2='287.54' />
			<line x1='189.09' y1='280.72' x2='189.09' y2='271.86' />
			<line x1='189.09' y1='269.09' x2='189.09' y2='265.04' />
			<line x1='189.09' y1='283.49' x2='189.09' y2='287.54' />
			<line x1='186.21' y1='280.72' x2='186.21' y2='271.85' />
			<line x1='186.21' y1='269.1' x2='186.21' y2='265.04' />
			<line x1='186.21' y1='283.48' x2='186.21' y2='287.54' />
			<line x1='186.49' y1='280.73' x2='186.49' y2='271.85' />
			<line x1='186.49' y1='269.1' x2='186.49' y2='265.04' />
			<line x1='186.49' y1='283.48' x2='186.49' y2='287.54' />
			<line x1='184.78' y1='280.62' x2='184.78' y2='271.96' />
			<line x1='184.78' y1='269' x2='184.78' y2='265.04' />
			<line x1='184.78' y1='283.59' x2='184.78' y2='287.54' />
			<line x1='185.06' y1='280.62' x2='185.06' y2='271.96' />
			<line x1='185.06' y1='269' x2='185.06' y2='265.04' />
			<line x1='185.06' y1='283.59' x2='185.06' y2='287.54' />
			<line x1='183.63' y1='280.71' x2='183.63' y2='271.87' />
			<line x1='183.63' y1='269.09' x2='183.63' y2='265.04' />
			<line x1='183.63' y1='283.49' x2='183.63' y2='287.54' />
			<line x1='183.35' y1='280.72' x2='183.35' y2='271.86' />
			<line x1='183.35' y1='269.09' x2='183.35' y2='265.04' />
			<line x1='183.35' y1='283.49' x2='183.35' y2='287.54' />
			<line x1='187.88' y1='287.54' x2='187.88' y2='282.22' />
			<line x1='187.88' y1='281.98' x2='187.88' y2='270.59' />
			<line x1='187.88' y1='270.36' x2='187.88' y2='265.04' />
			<line x1='187.59' y1='287.54' x2='187.59' y2='282.22' />
			<line x1='187.59' y1='281.98' x2='187.59' y2='270.59' />
			<line x1='187.59' y1='270.36' x2='187.59' y2='265.04' />
			<line x1='180.88' y1='276.29' x2='193.61' y2='276.29' />
			<line x1='181.4' y1='276.72' x2='181.97' y2='276.72' />
			<line x1='182.26' y1='276.72' x2='183.35' y2='276.72' />
			<line x1='183.63' y1='276.72' x2='184.78' y2='276.72' />
			<line x1='185.06' y1='276.72' x2='186.21' y2='276.72' />
			<line x1='186.49' y1='276.72' x2='187.59' y2='276.72' />
			<line x1='187.88' y1='276.72' x2='189.09' y2='276.72' />
			<line x1='189.38' y1='276.72' x2='190.52' y2='276.72' />
			<line x1='190.81' y1='276.72' x2='191.95' y2='276.72' />
			<line x1='192.24' y1='276.72' x2='193.09' y2='276.72' />
			<line x1='181.4' y1='275.86' x2='181.97' y2='275.86' />
			<line x1='182.26' y1='275.86' x2='183.35' y2='275.86' />
			<line x1='183.63' y1='275.86' x2='184.78' y2='275.86' />
			<line x1='185.06' y1='275.86' x2='186.21' y2='275.86' />
			<line x1='186.49' y1='275.86' x2='187.59' y2='275.86' />
			<line x1='187.88' y1='275.86' x2='189.09' y2='275.86' />
			<line x1='189.38' y1='275.86' x2='190.52' y2='275.86' />
			<line x1='190.81' y1='275.86' x2='191.95' y2='275.86' />
			<line x1='192.24' y1='275.86' x2='193.09' y2='275.86' />
			<line x1='193.09' y1='287.1' x2='192.24' y2='287.1' />
			<line x1='191.95' y1='287.1' x2='190.81' y2='287.1' />
			<line x1='190.52' y1='287.1' x2='189.38' y2='287.1' />
			<line x1='189.09' y1='287.1' x2='187.88' y2='287.1' />
			<line x1='187.59' y1='287.1' x2='186.49' y2='287.1' />
			<line x1='186.21' y1='287.1' x2='185.06' y2='287.1' />
			<line x1='184.78' y1='287.1' x2='183.63' y2='287.1' />
			<line x1='183.35' y1='287.1' x2='182.26' y2='287.1' />
			<line x1='181.97' y1='287.1' x2='181.4' y2='287.1' />
			<line x1='181.4' y1='265.47' x2='181.97' y2='265.47' />
			<line x1='182.26' y1='265.47' x2='183.35' y2='265.47' />
			<line x1='183.63' y1='265.47' x2='184.78' y2='265.47' />
			<line x1='185.06' y1='265.47' x2='186.21' y2='265.47' />
			<line x1='186.49' y1='265.47' x2='187.59' y2='265.47' />
			<line x1='187.88' y1='265.47' x2='189.09' y2='265.47' />
			<line x1='189.38' y1='265.47' x2='190.52' y2='265.47' />
			<line x1='190.81' y1='265.47' x2='191.95' y2='265.47' />
			<line x1='192.24' y1='265.47' x2='193.09' y2='265.47' />
			<line x1='193.09' y1='270.59' x2='192.14' y2='270.59' />
			<line x1='189.18' y1='270.59' x2='187.88' y2='270.59' />
			<line x1='187.59' y1='270.59' x2='186.4' y2='270.59' />
			<line x1='183.44' y1='270.59' x2='182.26' y2='270.59' />
			<line x1='181.97' y1='270.59' x2='181.4' y2='270.59' />
			<line x1='193.09' y1='270.36' x2='192.14' y2='270.36' />
			<line x1='189.18' y1='270.36' x2='187.88' y2='270.36' />
			<line x1='187.59' y1='270.36' x2='186.4' y2='270.36' />
			<line x1='183.44' y1='270.36' x2='182.26' y2='270.36' />
			<line x1='181.97' y1='270.36' x2='181.4' y2='270.36' />
			<line x1='183.44' y1='281.98' x2='182.26' y2='281.98' />
			<line x1='181.97' y1='281.98' x2='181.4' y2='281.98' />
			<line x1='186.4' y1='281.98' x2='187.59' y2='281.98' />
			<line x1='187.88' y1='281.98' x2='189.18' y2='281.98' />
			<line x1='192.14' y1='281.98' x2='193.09' y2='281.98' />
			<line x1='183.44' y1='282.22' x2='182.26' y2='282.22' />
			<line x1='181.97' y1='282.22' x2='181.4' y2='282.22' />
			<line x1='186.4' y1='282.22' x2='187.59' y2='282.22' />
			<line x1='187.88' y1='282.22' x2='189.18' y2='282.22' />
			<line x1='192.14' y1='282.22' x2='193.09' y2='282.22' />
			<circle cx='190.66' cy='270.48' r='0.86' />
			<path d='M191.6,271.23c0.36-0.44,0.36-1.08,0-1.52' />
			<path d='M191.46,269.57c-0.45-0.4-1.13-0.4-1.59-0.01' />
			<path d='M189.73,269.71c-0.36,0.45-0.36,1.09,0,1.53' />
			<path d='M189.87,271.39c0.46,0.4,1.14,0.39,1.59-0.01' />
			<circle cx='190.66' cy='282.1' r='0.75' />
			<path d='M189.91,282.98c0.44,0.38,1.08,0.37,1.52-0.01' />
			<path d='M191.57,282.83c0.34-0.42,0.34-1.02,0-1.45' />
			<path d='M191.43,281.23c-0.43-0.38-1.08-0.38-1.52-0.01' />
			<path d='M189.77,281.37c-0.35,0.43-0.35,1.03,0,1.46' />
			<path d='M183.84,281.2c-0.44,0.52-0.44,1.29,0,1.81' />
			<path d='M183.98,283.16c0.54,0.48,1.35,0.48,1.88-0.01' />
			<path d='M186.01,283.01c0.43-0.52,0.43-1.28,0-1.8' />
			<path d='M185.87,281.06c-0.53-0.48-1.35-0.48-1.88-0.01' />
			<path d='M183.12,282.22c0.03,0.39,0.18,0.76,0.44,1.06' />
			<path d='M183.7,283.43c0.3,0.27,0.68,0.44,1.08,0.47' />
			<path d='M185.06,283.9c0.41-0.03,0.79-0.2,1.09-0.48' />
			<path d='M186.29,283.28c0.25-0.3,0.41-0.67,0.43-1.06' />
			<path d='M186.72,281.98c-0.03-0.39-0.18-0.76-0.43-1.06' />
			<path d='M186.15,280.78c-0.3-0.28-0.68-0.45-1.09-0.48' />
			<path d='M184.78,280.3c-0.4,0.03-0.78,0.2-1.08,0.47' />
			<path d='M183.56,280.92c-0.26,0.3-0.41,0.67-0.44,1.06' />
			<path d='M186.44,270.36c-0.02-0.32-0.15-0.62-0.35-0.86' />
			<path d='M185.95,269.35c-0.24-0.22-0.55-0.36-0.88-0.39' />
			<path d='M184.78,268.96c-0.33,0.03-0.63,0.17-0.88,0.39' />
			<path d='M183.76,269.49c-0.21,0.24-0.33,0.55-0.36,0.87' />
			<path d='M183.4,270.59c0.03,0.32,0.15,0.62,0.36,0.87' />
			<path d='M183.9,271.61c0.24,0.22,0.55,0.36,0.88,0.39' />
			<path d='M185.06,271.99c0.33-0.03,0.64-0.17,0.88-0.39' />
			<path d='M186.09,271.46c0.2-0.24,0.33-0.54,0.35-0.86' />
			<path d='M186.76,270.36c-0.03-0.4-0.18-0.78-0.44-1.09' />
			<path d='M186.18,269.12c-0.31-0.29-0.7-0.46-1.12-0.49' />
			<path d='M184.78,268.63c-0.41,0.03-0.81,0.2-1.11,0.49' />
			<path d='M183.52,269.26c-0.26,0.31-0.42,0.69-0.45,1.09' />
			<path d='M183.08,270.59c0.03,0.4,0.18,0.79,0.45,1.09' />
			<path d='M183.67,271.83c0.31,0.28,0.7,0.45,1.11,0.49' />
			<path d='M185.06,272.32c0.42-0.03,0.81-0.21,1.12-0.49' />
			<path d='M186.32,271.68c0.26-0.3,0.42-0.69,0.44-1.09' />
			<line x1='184.1' y1='282.76' x2='183.35' y2='283.49' />
			<line x1='185.6' y1='281.31' x2='186.21' y2='280.72' />
			<line x1='184.24' y1='282.9' x2='183.63' y2='283.49' />
			<line x1='185.74' y1='281.45' x2='186.49' y2='280.73' />
			<line x1='184.1' y1='281.45' x2='183.35' y2='280.72' />
			<line x1='185.6' y1='282.9' x2='186.21' y2='283.48' />
			<line x1='184.24' y1='281.3' x2='183.63' y2='280.71' />
			<line x1='185.74' y1='282.75' x2='186.49' y2='283.48' />
			<line x1='185.06' y1='280.62' x2='184.78' y2='280.62' />
			<line x1='185.06' y1='283.59' x2='184.78' y2='283.59' />
			<line x1='185.6' y1='281.31' x2='185.74' y2='281.45' />
			<line x1='185.6' y1='282.9' x2='185.74' y2='282.75' />
			<line x1='184.24' y1='281.3' x2='184.1' y2='281.45' />
			<line x1='184.24' y1='282.9' x2='184.1' y2='282.76' />
			<line x1='186.4' y1='281.98' x2='186.4' y2='282.22' />
			<line x1='183.44' y1='281.98' x2='183.44' y2='282.22' />
			<line x1='189.85' y1='282.76' x2='189.09' y2='283.49' />
			<line x1='191.35' y1='281.31' x2='191.95' y2='280.72' />
			<line x1='189.99' y1='282.9' x2='189.38' y2='283.49' />
			<line x1='191.49' y1='281.45' x2='192.24' y2='280.73' />
			<line x1='189.85' y1='281.45' x2='189.09' y2='280.72' />
			<line x1='191.35' y1='282.9' x2='191.95' y2='283.48' />
			<line x1='189.99' y1='281.3' x2='189.38' y2='280.71' />
			<line x1='191.49' y1='282.75' x2='192.24' y2='283.48' />
			<line x1='190.81' y1='280.62' x2='190.52' y2='280.62' />
			<line x1='190.81' y1='283.59' x2='190.52' y2='283.59' />
			<line x1='191.35' y1='281.31' x2='191.49' y2='281.45' />
			<line x1='191.35' y1='282.9' x2='191.49' y2='282.75' />
			<line x1='189.99' y1='281.3' x2='189.85' y2='281.45' />
			<line x1='189.99' y1='282.9' x2='189.85' y2='282.76' />
			<line x1='192.14' y1='281.98' x2='192.14' y2='282.22' />
			<line x1='189.18' y1='281.98' x2='189.18' y2='282.22' />
			<line x1='189.85' y1='271.13' x2='189.09' y2='271.86' />
			<line x1='191.35' y1='269.68' x2='191.95' y2='269.1' />
			<line x1='189.99' y1='271.28' x2='189.38' y2='271.87' />
			<line x1='191.49' y1='269.83' x2='192.24' y2='269.1' />
			<line x1='189.85' y1='269.82' x2='189.09' y2='269.09' />
			<line x1='191.35' y1='271.27' x2='191.95' y2='271.85' />
			<line x1='189.99' y1='269.67' x2='189.38' y2='269.09' />
			<line x1='191.49' y1='271.12' x2='192.24' y2='271.85' />
			<line x1='190.81' y1='269' x2='190.52' y2='269' />
			<line x1='190.81' y1='271.96' x2='190.52' y2='271.96' />
			<line x1='191.35' y1='269.68' x2='191.49' y2='269.83' />
			<line x1='191.35' y1='271.27' x2='191.49' y2='271.12' />
			<line x1='189.99' y1='269.67' x2='189.85' y2='269.82' />
			<line x1='189.99' y1='271.28' x2='189.85' y2='271.13' />
			<line x1='192.14' y1='270.36' x2='192.14' y2='270.59' />
			<line x1='189.18' y1='270.36' x2='189.18' y2='270.59' />
			<line x1='184.1' y1='271.13' x2='183.35' y2='271.86' />
			<line x1='185.6' y1='269.68' x2='186.21' y2='269.1' />
			<line x1='184.24' y1='271.28' x2='183.63' y2='271.87' />
			<line x1='185.74' y1='269.83' x2='186.49' y2='269.1' />
			<line x1='184.1' y1='269.82' x2='183.35' y2='269.09' />
			<line x1='185.6' y1='271.27' x2='186.21' y2='271.85' />
			<line x1='184.24' y1='269.67' x2='183.63' y2='269.09' />
			<line x1='185.74' y1='271.12' x2='186.49' y2='271.85' />
			<line x1='185.06' y1='269' x2='184.78' y2='269' />
			<line x1='185.06' y1='271.96' x2='184.78' y2='271.96' />
			<line x1='185.6' y1='269.68' x2='185.74' y2='269.83' />
			<line x1='185.6' y1='271.27' x2='185.74' y2='271.12' />
			<line x1='184.24' y1='269.67' x2='184.1' y2='269.82' />
			<line x1='184.24' y1='271.28' x2='184.1' y2='271.13' />
			<line x1='186.4' y1='270.36' x2='186.4' y2='270.59' />
			<line x1='183.44' y1='270.36' x2='183.44' y2='270.59' />
		</g>
	);
};

export default ThinLineworks;
