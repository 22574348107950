import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<path d='M112.97,146.93c0.71,13.57,11.92,24.21,25.51,24.21' />
			<path d='M168.43,80.91c-13.57,0.71-24.21,11.92-24.21,25.51' />
			<path d='M168.92,75.53c0-13.59-10.64-24.8-24.21-25.51' />
			<path d='M281.48,123.49c-13.57,0.71-24.21,11.92-24.21,25.51' />
			<path d='M81.66,103.41c1.81-3.57,2.75-7.52,2.75-11.52' />
			<path d='M288.61,163.44c0.74,14.39,12.61,25.68,27.02,25.69' />
			<path d='M141.96,136.25c1.52,0,3.04-0.15,4.54-0.46' />
			<path d='M222.91,185.58c0-1.13-0.11-2.26-0.32-3.37' />
			<rect x='102.83' y='342.08' width='0.75' height='17.59' />
			<path d='M77.01,337.59c0,14.39,11.45,26.16,25.84,26.56' />
		</g>
	);
};

export default DoorSwings;
