import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Ensuite: IScene = {
	name: 'Ensuite',
	pan: '0deg',
	hotspot: {
		x: 155,
		y: 50,
	},
	images: {
		scheme1: Scheme1.Ensuite,
		scheme2: Scheme2.Ensuite,
	},
	default: {
		latitude: -0.027554604920626335,
		longitude: 1.5756179526966312,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7448879831946273,
				longitude: 3.8494163170361526,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.12844757073723878,
					longitude: 5.883543399222842,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.32415485289618307,
				longitude: 3.246628765090766,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: -0.11303640346470845,
					longitude: 3.1823847468167124,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.10956488076799342,
				longitude: 3.1900508271607086,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.07044071740649294,
					longitude: 2.4472670971352906,
				},
			},
		},
	],
};

export default Ensuite;
