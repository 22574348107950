import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<rect x='76.26' y='334.21' width='0.75' height='3.38' />
			<rect x='62.88' y='334.21' width='13.38' height='3.38' />
			<rect x='61.38' y='334.21' width='1.5' height='3.38' />
			<rect x='36.31' y='334.21' width='25.07' height='3.38' />
			<rect x='34.81' y='334.21' width='1.5' height='3.38' />
			<rect x='9.92' y='334.21' width='24.88' height='3.38' />
			<line x1='61.38' y1='335.9' x2='36.31' y2='335.9' />
			<line x1='34.81' y1='335.9' x2='9.92' y2='335.9' />

			<rect
				x='-4.26'
				y='319.27'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -312.7651 312.3265)'
				width='24.87'
				height='3.38'
			/>
			<polyline points='7.14,308.45 7.22,306.95 10.59,307.13 10.52,308.62 ' />
			<line x1='8.83' y1='308.54' x2='7.53' y2='333.38' />
			<line x1='8.91' y1='307.04' x2='9.99' y2='286.42' />
			<line x1='10.07' y1='284.92' x2='11.38' y2='259.88' />

			<rect
				x='-0.87'
				y='295.04'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -287.3681 290.6355)'
				width='20.65'
				height='3.38'
			/>

			<rect
				x='9.28'
				y='283.98'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -275.7765 280.7352)'
				width='1.5'
				height='3.38'
			/>

			<rect
				x='-1.82'
				y='270.72'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -261.8701 268.8468)'
				width='25.08'
				height='3.36'
			/>

			<rect
				x='10.67'
				y='257.44'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -247.9526 256.9714)'
				width='1.5'
				height='3.38'
			/>
			<polygon points='9.77,258.29 11.06,233.69 14.42,233.86 13.14,258.47 ' />
			<polygon points='11.14,232.19 11.06,233.69 14.42,233.86 14.51,232.37 ' />
			<polygon points='11.14,232.19 12.43,207.58 15.8,207.76 14.5,232.36 ' />
			<polygon points='12.43,207.58 12.51,206.09 15.88,206.26 15.79,207.76 ' />

			<rect
				x='2.31'
				y='191.96'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -179.3089 198.3442)'
				width='25.09'
				height='3.38'
			/>
			<line x1='14.19' y1='206.17' x2='15.51' y2='181.12' />

			<rect
				x='14.8'
				y='178.69'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -165.3932 186.4591)'
				width='1.5'
				height='3.38'
			/>

			<rect
				x='5.8'
				y='167.63'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -153.8015 176.5588)'
				width='20.65'
				height='3.38'
			/>
			<line x1='15.59' y1='179.62' x2='16.67' y2='159.01' />

			<rect
				x='15.96'
				y='156.57'
				transform='matrix(0.0523 -0.9986 0.9986 0.0523 -142.2099 166.6586)'
				width='1.5'
				height='3.38'
			/>

			<rect
				x='-0.97'
				y='137.15'
				transform='matrix(0.0527 -0.9986 0.9986 0.0527 -121.856 149.2255)'
				width='37.39'
				height='3.37'
			/>
			<polygon points='17.06,119.29 17.22,116.13 20.43,116.13 20.43,119.51 20.43,119.51 20.43,119.51 ' />
			<rect x='21.18' y='116.13' width='26.86' height='3.38' />
			<rect x='48.04' y='116.13' width='0.75' height='3.38' />
			<rect x='48.79' y='115.38' width='3.38' height='0.75' />
			<rect x='48.79' y='116.13' width='3.38' height='3.38' />
			<rect x='20.43' y='116.13' width='0.75' height='3.38' />
			<line x1='20.43' y1='119.51' x2='17.06' y2='119.29' />
			<line x1='17.06' y1='119.29' x2='17.01' y2='120.08' />
			<line x1='17.01' y1='120.08' x2='20.39' y2='120.26' />
			<line x1='20.39' y1='120.26' x2='20.43' y2='119.47' />
			<rect x='48.79' y='100.64' width='3.38' height='14.75' />
			<rect x='48.79' y='99.14' width='3.38' height='1.5' />
			<rect x='48.79' y='64.32' width='3.38' height='34.82' />
			<rect x='48.79' y='62.82' width='3.38' height='1.5' />
			<rect x='48.79' y='37.74' width='3.38' height='25.07' />
			<rect x='48.79' y='36.24' width='3.38' height='1.5' />
			<rect x='48.79' y='14.85' width='3.38' height='21.4' />
			<rect x='48.79' y='14.09' width='3.38' height='0.76' />
			<line x1='50.47' y1='14.85' x2='50.47' y2='36.24' />
			<line x1='50.47' y1='37.74' x2='50.47' y2='62.82' />
			<rect x='103.58' y='334.21' width='3.38' height='2.59' />
			<rect x='103.58' y='336.81' width='3.38' height='1.53' />
			<rect x='103.58' y='338.34' width='3.38' height='30.98' />
			<rect x='103.56' y='369.32' width='3.4' height='1.5' />
			<rect x='103.56' y='370.82' width='3.4' height='30.98' />
			<rect x='103.56' y='401.8' width='3.4' height='1.5' />
			<rect x='103.56' y='403.3' width='3.4' height='29.97' />
			<polygon points='107.71,434.02 106.96,434.02 106.96,433.27 103.56,433.27 103.58,437.24 107.71,437.24 ' />
			<polygon points='5.43,338.98 0.26,437.51 102.99,437.51 102.99,436.39 1.45,436.39 6.55,339.04 ' />
			<rect x='108.93' y='173.45' width='18.76' height='1.48' />
			<rect x='118.31' y='174.92' width='18.76' height='1.48' />
			<rect x='238.41' y='76.42' width='45' height='24' />
			<path
				d='M259.84,98.04l-17.19-2.07c-1.13-0.14-1.98-1.1-1.98-2.23V83.1c0-1.14,0.85-2.1,1.98-2.23l17.19-2.07
c0.71-0.09,1.44-0.09,2.15,0l17.19,2.07c1.13,0.14,1.98,1.1,1.98,2.23v10.63c0,1.14-0.85,2.1-1.98,2.23l-17.19,2.07
C261.27,98.12,260.55,98.12,259.84,98.04z'
			/>
			<rect x='112.97' y='145.6' width='25.51' height='1.33' />
			<rect x='168.43' y='80.91' width='1.33' height='25.51' />
			<rect x='143.38' y='50.02' width='1.33' height='25.51' />
			<rect x='281.48' y='123.49' width='1.33' height='25.51' />

			<rect
				x='69.6'
				y='84.89'
				transform='matrix(0.4965 -0.868 0.868 0.4965 -49.3837 110.1521)'
				width='1.33'
				height='25.51'
			/>
			<rect x='288.61' y='162.11' width='27.02' height='1.33' />
			<polyline points='255.29,149 255.29,124.42 244.2,124.42 244.2,100.42 ' />
			<line x1='254.19' y1='144.55' x2='242.19' y2='144.55' />
			<line x1='244.94' y1='136.07' x2='244.94' y2='136.83' />
			<path d='M243.64,137.02c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='243.83' y1='136.07' x2='243.83' y2='136.83' />
			<path d='M243.83,136.07c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='244.76' y1='137.02' x2='240.53' y2='137.02' />
			<path d='M244.76,137.02c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='244.94' y1='136.07' x2='244.94' y2='136.83' />
			<path d='M244.94,136.07c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='240.53' y1='135.88' x2='244.76' y2='135.88' />
			<path d='M240.39,136.88c0,0.08,0.06,0.14,0.14,0.14' />
			<line x1='240.39' y1='136.02' x2='240.39' y2='136.88' />
			<path d='M240.53,135.88c-0.08,0-0.14,0.06-0.14,0.14' />
			<line x1='242.19' y1='128.35' x2='242.19' y2='135.88' />
			<line x1='254.19' y1='128.35' x2='242.19' y2='128.35' />
			<line x1='254.19' y1='144.55' x2='254.19' y2='128.35' />
			<line x1='242.19' y1='137.02' x2='242.19' y2='144.55' />
			<path
				d='M252.92,118.6c0.67,0,1.06-0.07,1.48-0.14c0.54-0.09,1.12-0.2,1.65-0.4c0.55-0.21,1.04-0.53,1.43-0.86
c0.3-0.25,0.55-0.5,0.73-0.73c0.15-0.19,0.24-0.36,0.35-0.6c0.1-0.23,0.2-0.51,0.28-0.8c0.08-0.31,0.14-0.62,0.17-0.91
c0.03-0.3,0.03-0.57,0.03-0.91c0-0.32,0-0.7-0.03-1.04c-0.03-0.32-0.08-0.61-0.12-0.87c-0.04-0.22-0.08-0.43-0.15-0.63
c-0.08-0.21-0.19-0.41-0.32-0.61c-0.18-0.29-0.38-0.58-0.71-0.89c-0.35-0.33-0.84-0.69-1.26-0.92c-0.41-0.22-0.76-0.32-1.1-0.41
c-0.29-0.08-0.56-0.15-1.04-0.23c-0.39-0.07-0.92-0.13-1.4-0.13'
			/>
			<line x1='252.92' y1='107.54' x2='244.2' y2='107.54' />
			<line x1='252.92' y1='118.6' x2='244.2' y2='118.6' />
			<polyline points='189.72,75.53 172.87,75.53 172.87,51.53 183.95,51.53 183.95,27.58 189.72,27.58 ' />
			<line x1='173.97' y1='71.66' x2='185.97' y2='71.66' />
			<line x1='183.21' y1='63.18' x2='183.21' y2='63.95' />
			<path d='M184.33,63.95c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='184.33' y1='63.18' x2='184.33' y2='63.95' />
			<path d='M184.52,63c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='183.4' y1='64.14' x2='187.62' y2='64.14' />
			<path d='M183.21,63.95c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='183.21' y1='63.18' x2='183.21' y2='63.95' />
			<path d='M183.4,63c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='187.62' y1='63' x2='183.4' y2='63' />
			<path d='M187.62,64.14c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='187.77' y1='63.14' x2='187.77' y2='63.99' />
			<path d='M187.77,63.14c0-0.08-0.06-0.14-0.14-0.14' />
			<line x1='185.97' y1='55.47' x2='185.97' y2='63' />
			<line x1='173.97' y1='55.47' x2='185.97' y2='55.47' />
			<line x1='173.97' y1='71.66' x2='173.97' y2='55.47' />
			<line x1='185.97' y1='64.14' x2='185.97' y2='71.66' />
			<path
				d='M175.24,45.71c-0.67,0-1.06-0.07-1.48-0.14c-0.54-0.09-1.12-0.2-1.65-0.4c-0.55-0.21-1.04-0.53-1.43-0.86
c-0.3-0.25-0.55-0.5-0.73-0.73c-0.15-0.19-0.24-0.36-0.35-0.6c-0.1-0.23-0.2-0.51-0.28-0.8c-0.08-0.31-0.14-0.62-0.17-0.91
c-0.03-0.3-0.03-0.57-0.03-0.91c0-0.32,0-0.7,0.03-1.04c0.03-0.32,0.08-0.61,0.12-0.87c0.04-0.22,0.08-0.43,0.15-0.63
c0.08-0.21,0.19-0.41,0.32-0.61c0.18-0.29,0.38-0.58,0.71-0.89c0.35-0.33,0.84-0.69,1.26-0.92c0.41-0.22,0.76-0.32,1.1-0.41
c0.29-0.08,0.56-0.15,1.04-0.23c0.39-0.07,0.92-0.13,1.4-0.13'
			/>
			<line x1='175.24' y1='34.65' x2='183.95' y2='34.65' />
			<line x1='175.24' y1='45.71' x2='183.95' y2='45.71' />
			<rect x='114.77' y='115.01' width='22.69' height='20.44' />

			<rect
				x='143.56'
				y='113.54'
				transform='matrix(0.9659 -0.2588 0.2588 0.9659 -27.3836 41.5806)'
				width='1.33'
				height='22.5'
			/>

			<rect
				x='204.77'
				y='183.23'
				transform='matrix(0.9659 -0.2588 0.2588 0.9659 -40.3131 61.5881)'
				width='17.96'
				height='1.33'
			/>
			<polyline points='176.51,214.02 176.51,294.79 125.12,294.79 125.12,312.79 ' />
			<rect x='176.51' y='235.77' width='18.03' height='0.75' />
			<rect x='176.51' y='214.02' width='18' height='0.75' />
			<rect x='102.83' y='359.66' width='0.75' height='4.5' />
			<rect x='102.83' y='337.59' width='0.75' height='4.49' />

			<rect
				x='157.25'
				y='17.18'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 89.9854 172.7349)'
				width='0.59'
				height='21.11'
			/>
			<rect x='145.06' y='24.69' width='2.21' height='0.59' />
			<rect x='168.39' y='24.69' width='21.33' height='0.59' />
			<line x1='103.58' y1='437.24' x2='102.99' y2='437.24' />
			<rect x='107.71' y='434.02' width='1.7' height='3.23' />
			<line x1='145.06' y1='21.01' x2='145.06' y2='9.2' />
			<line x1='5.62' y1='337.59' x2='5.54' y2='338.99' />
			<polygon points='9.17,334.21 9.21,333.46 5.84,333.29 5.62,337.59 9.92,337.59 9.92,334.21 ' />
			<rect x='125.69' y='294.41' width='18' height='0.38' />
			<path d='M142.68,294.41c-5.15-1.95-10.84-1.95-15.99,0' />
			<line x1='100.43' y1='129.55' x2='100.43' y2='91.86' />
			<line x1='60.39' y1='129.55' x2='109.43' y2='129.55' />
			<line x1='99.85' y1='186' x2='138.47' y2='186' />
			<line x1='202.48' y1='197.67' x2='225.24' y2='197.67' />
			<rect x='146.95' y='296.08' width='21' height='15.75' />
			<path d='M157.03,310.7c0,0.23,0.19,0.42,0.42,0.42c0.23,0,0.42-0.19,0.42-0.42' />
			<path
				d='M157.03,310.19c-0.28,0.23-0.32,0.65-0.09,0.93s0.65,0.32,0.93,0.09c0.28-0.23,0.32-0.65,0.09-0.93
c-0.03-0.03-0.06-0.06-0.09-0.09'
			/>
			<polyline points='157.03,310.7 157.03,310.7 157.03,305.33 ' />
			<line x1='157.87' y1='305.33' x2='157.87' y2='310.7' />
			<polyline points='155.4,311.36 155.4,310.04 157.03,310.04 ' />
			<line x1='155.4' y1='311.36' x2='157.45' y2='311.36' />
			<path d='M157.87,305.33c0-0.23-0.19-0.42-0.42-0.42c-0.23,0-0.42,0.19-0.42,0.42' />
			<rect x='176.13' y='267.29' width='0.38' height='18' />
			<path d='M176.13,268.29c-1.95,5.15-1.95,10.84,0,15.99' />
			<line x1='177.86' y1='265.04' x2='193.38' y2='265.04' />
			<line x1='177.86' y1='287.54' x2='193.38' y2='287.54' />
			<circle cx='179.16' cy='268.8' r='0.68' />
			<circle cx='179.16' cy='273.78' r='0.68' />
			<circle cx='179.16' cy='283.78' r='0.68' />
			<circle cx='179.16' cy='278.8' r='0.68' />
			<line x1='193.61' y1='287.31' x2='193.61' y2='265.27' />
			<line x1='177.63' y1='265.27' x2='177.63' y2='287.31' />
			<path d='M177.86,265.04c-0.13,0-0.23,0.1-0.23,0.23' />
			<path d='M177.63,287.31c0,0.13,0.1,0.23,0.23,0.23' />
			<path d='M193.38,287.54c0.13,0,0.23-0.1,0.23-0.23' />
			<path d='M193.61,265.27c0-0.13-0.1-0.23-0.23-0.23' />
		</g>
	);
};

export default Lineworks;
