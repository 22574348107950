import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='143.38' y1='77.27' x2='168.92' y2='77.27' />
			<line x1='282.81' y1='150.74' x2='257.27' y2='150.74' />
			<line x1='140.21' y1='135.81' x2='140.21' y2='113.75' />
			<polyline points='76.12,334.21 76.12,331.76 11.74,331.76 15.45,261.07 ' />
			<line x1='18.28' y1='207.11' x2='20.9' y2='157.13' />
			<line x1='54.61' y1='14.08' x2='54.61' y2='63.57' />
		</g>
	);
};

export default index;
