import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MasterBedroom: IScene = {
	name: 'Master Bedroom',
	pan: '0deg',
	hotspot: {
		x: 90,
		y: 70,
	},
	images: {
		scheme1: Scheme1.MasterBedroom,
		scheme2: Scheme2.MasterBedroom,
	},
	default: {
		latitude: -0.027817074072192582,
		longitude: 6.268695426062184,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5211745578491866,
				longitude: 1.685063174287845,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: -0.09954017916129754,
					longitude: 1.5740452593865097,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5028796165195262,
				longitude: 1.9708743290635613,
			},
			target: {
				name: 'Hallway',
				view: {
					latitude: 0.04064666883621326,
					longitude: 3.137607866305399,
				},
			},
		},
	],
};

export default MasterBedroom;
