import IScene from './IScene';
import Bedroom from './Bedroom';
import Hallway from './Hallway';
import MainBath from './MainBath';
import Kitchen from './Kitchen';
import Living from './Living';
import MasterBedroom from './MasterBedroom';
import Ensuite from './Ensuite';

const Scenes: Array<IScene> = [
	Kitchen,
	Hallway,
	Living,
	MasterBedroom,
	Ensuite,
	Bedroom,
	MainBath,
];

export default Scenes;
