import {useSelector, useDispatch} from 'react-redux';
import Backdrop from './Backdrop';
import DoorSwings from './DoorSwings';
import Dashed from './Dashed';
import ThinLineworks from './ThinLineworks';
import Lineworks from './Lineworks';
import Furniture from './Furniture';
import Flooring from './Flooring';
import Walls from './Walls';
import HotSpot from './Hotspot';
// import Text from './Text'
import SCSS from './SVG.module.scss';
import {
	selectSCENES,
	selectCompass,
	selectCurrent,
	fetchPanorama,
	updateClicked,
} from '../../../../features/panorama/panoramaSlice';
import IScene from '../../../../scenes/IScene';

const _ = require('lodash');

const SVG = () => {
	const SCENES = useSelector(selectSCENES);
	const CurrentScene = useSelector(selectCurrent);
	// const INTERIORS = _.filter(SCENES, ['category', 'interiors'])
	const Compass = useSelector(selectCompass);
	const dispatch = useDispatch();

	const changeScene = (scene: string): void => {
		dispatch(updateClicked('miniplan'));
		dispatch(fetchPanorama(scene));
	};

	const HotSpots = _.map(SCENES, (scene: IScene, index: number) => {
		return (
			<HotSpot
				key={index}
				name={scene.name}
				currentName={CurrentScene.name}
				degree={Compass.view}
				x={scene.hotspot.x}
				y={scene.hotspot.y}
				changeScene={changeScene}
			/>
		);
	});

	return (
		<svg
			viewBox='0 0 327.5 437.76'
			className={SCSS.SVG}
			onClick={(event: any) => {
				console.log(event);
			}}>
			<Backdrop />
			<DoorSwings />
			<Dashed />
			<ThinLineworks />
			<Lineworks />
			<Furniture />
			<Flooring />
			<Walls />
			{HotSpots}
			{/* <Text /> */}
		</svg>
	);
};

export default SVG;
