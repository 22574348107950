import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<polyline
				points='13,260.94 15.45,261.07 17.24,226.83 99.85,226.83 99.85,198.48 141.96,198.48 141.96,171.14 
138.47,171.14 138.47,173.45 137.07,173.45 137.07,176.93 138.47,176.93 138.47,195 99.85,195 99.85,176.93 101.05,176.93 
101.05,173.45 96.36,173.45 96.36,223.35 17.43,223.35 18.28,207.11 15.84,206.98 13.01,260.94 '
			/>
			<polyline
				points='18.45,157 20.9,157.13 21.68,142.09 138.47,142.09 138.47,145.6 141.96,145.6 141.96,136.25 
138.47,136.25 138.47,138.6 112.91,138.6 112.91,111.67 138.47,111.67 138.47,113.75 141.96,113.75 141.96,109.9 144.22,109.9 
144.22,106.42 112.91,106.42 112.91,88.41 84.4,88.41 84.4,91.89 109.43,91.84 109.43,138.58 60.39,138.55 60.39,119.29 
54.61,119.29 54.61,91.89 58.86,91.89 58.86,88.41 54.61,88.41 54.61,63.57 52.16,63.57 52.16,119.51 52.16,119.51 52.16,119.51 
20.43,119.51 18.45,157 '
			/>
			<polygon
				points='235.18,156.89 175.8,156.89 175.8,78.77 192.96,78.77 192.96,0.25 48.64,0.25 48.64,14.1 
54.61,14.08 54.61,3.48 137.84,3.48 137.84,79.02 143.38,79.02 143.38,75.53 141.33,75.53 141.33,27.58 145.06,27.58 
145.06,21.01 143.14,21.01 143.14,21.01 143.14,21.01 143.14,9.2 143.14,9.2 143.14,9.2 145.06,9.2 145.06,3.48 189.72,3.48 
189.72,75.53 168.92,75.53 168.92,79.02 172.57,79.02 172.57,106.42 169.76,106.42 169.76,109.9 172.57,109.9 172.57,160.13 
238.41,160.13 238.41,152.48 255.79,152.48 257.27,152.48 257.27,149 238.41,149 238.41,76.42 283.41,76.42 283.41,82.03 
285.33,82.03 285.33,93.84 283.41,93.84 283.41,100.42 285.33,100.42 285.36,149 282.81,149 282.81,152.48 285.33,152.48 
285.33,160.1 315.63,160.1 315.63,162.11 319.76,162.11 319.76,156.35 289.08,156.35 289.08,73.18 235.18,73.18 '
			/>
			<polygon
				points='109.41,337.59 128.38,337.59 128.38,316.02 197.77,316.02 197.77,209.87 270.23,209.87 
270.23,194.81 319.76,194.81 319.76,189.12 315.63,189.12 315.63,191.57 267,191.57 267,206.63 230.5,206.63 230.5,185.58 
222.91,185.58 222.91,189.07 225.24,189.07 225.24,206.63 202.48,206.63 202.48,189.07 204.9,189.07 204.9,185.58 185.92,185.58 
185.92,214.02 194.51,214.02 194.51,312.79 125.12,312.79 125.12,331.76 103.58,331.76 103.58,334.21 106.96,334.21 
106.96,337.59 106.96,434.02 109.41,434.02 '
			/>
			<circle cx='22.51' cy='353.16' r='11.22' />
		</g>
	);
};
export default Walls;
